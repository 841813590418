
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Reservation from './pages/Reservation'
import React from 'react';
import { HashRouter } from 'react-router-dom';
import NavigationComponent from './component/NavigationComponent'
import env from './env/env.json';
import InfoMessage from './component/common/InfoMessage';
import GoogleAnalyticInit, { initGA } from './component/common/GoogleAnalyticInit';

initGA()

function App() {
  return (
    <HashRouter>
     
      <div className="App">
        <div className='main-section'>


        </div>
        <InfoMessage />
        <GoogleAnalyticInit/>

        <NavigationComponent/> 
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/reservation" element={<Reservation />} />
            <Route path="/about" element={<About />} />
            <Route path="/adminnn" element={ <button onClick={() => window.location.replace( env.adminUrl )}>navigate</button> }
             />
          </Routes>

      </div>
    </HashRouter>
  );
}

export default App;