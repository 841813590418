import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";

//  <Navbar.Brand className='text-secondary' href="home">CL Vet</Navbar.Brand>

function NavigationComponent() {
  return (
    <Navbar expand="lg" className="bg-body-bg-dark">
      <Container>
       
        <Navbar.Toggle class="navbar navbar-light" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <Link class="text-secondary nav-link" to="/">Accueil</Link>
          <Link class="text-secondary nav-link" to="/reservation">Réservation</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationComponent;