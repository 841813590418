import logo from '../logo-nb.png';

function HeaderComponent({ pageName, extraComponent }) {

    return (<header className="App-header">

            <img src={logo} className="App-MainLogo" alt="logo" />
                <br/>

                <h3> {pageName} {extraComponent}</h3>
                
            </header>
        );
}

export default HeaderComponent;