import env from '../env/env.json';

function CalendarGet() {
    const APIEndPoint =  env.publicApi + 'calendar'


    const get =  fetch(APIEndPoint,{headers: {
            "X-API-Key": env.publicKey,
            "Content-Type": "application/json"
         }
         })
     .then((response) => response.json())
     .then((data) => {
         if (data.statusCode === 200) {
            //console.log(data);
            return (data.body);
         }
         else {
            //TODO : Error
            console.error(data);
         }
        
     })
     .catch((err) => {
        console.error(err.message);
        return [""]
     })

     return get
}


export default CalendarGet