export const scheduleModel = {
  "locale": "fr",
  "completedHtml": {
   "fr": "<h3>Merci, votre demande de consultation a été envoyée.</h3> <p>Vous recevrez plus de détails par courriel sous peu. <br/>Pour toute question, n'hésitez pas à communiquer avec nous à <a href=\"mailto:info@cindyvet.com\">info@cindyvet.com</a></p>"
  },
  "pages": [
   {
    "name": "before",
    "elements": [
     {
      "type": "html",
      "name": "question2",
      "html": {
       "fr": "Prenez note que les frais de consultations vétérinaires sont de <b>85$</b> pour une consultation régulière et de <b>125$</b> pour une consultation d'urgence.\n<br/>\nDes frais additionnels pourraient être applicables selon les  services demandés (suivis, prescription ...)"
      }
     }
    ],
    "title": {
     "fr": "Avant de débuter"
    }
   },
   {
    "name": "preQuestion",
    "elements": [
     {
      "type": "boolean",
      "name": "inQc",
      "title": {
       "fr": "Êtes-ce que vous et votre animal êtes actuellement dans la province de Québec?"
      },
      "isRequired": true,
      "labelTrue": "Oui",
      "labelFalse": "Non"
     },
     {
      "type": "boolean",
      "name": "firstTime",
      "title": {
       "fr": "Êtes-ce votre première consultation avec nous?"
      },
      "isRequired": true,
      "labelTrue": "Oui",
      "labelFalse": "Non"
     }
    ],
    "title": "Premier pas"
   },
   {
    "name": "preQuestionFailed",
    "elements": [
     {
      "type": "html",
      "name": "question6",
      "html": "Désolé, il est impossible d'offrir le service de télémédecine si vous êtes actuellement à l'extérieur du Québec."
     }
    ],
    "visible": false,
    "visibleIf": "{inQc} = false",
    "title": "Service impossible"
   },
   {
    "name": "clientInfo",
    "elements": [
     {
      "type": "text",
      "name": "firstname",
      "title": "Prénom",
      "isRequired": true
     },
     {
      "type": "text",
      "name": "lastname",
      "startWithNewLine": false,
      "title": "Nom",
      "isRequired": true
     },
     {
      "type": "text",
      "name": "phone",
      "title": "Téléphone",
      "isRequired": true,
      "inputType": "tel"
     },
     {
      "type": "text",
      "name": "email",
      "startWithNewLine": false,
      "title": "Adresse courriel",
      "isRequired": true,
      "inputType": "email"
     },
     {
      "type": "comment",
      "name": "address",
      "title": {
       "fr": "Adresse"
      },
      "isRequired": true,
      "maxLength": 300
     }
    ],
    "visible": false,
    "visibleIf": "{inQc} = true",
    "title": "Information générale"
   },
   {
    "name": "petInformation",
    "elements": [
     {
      "type": "radiogroup",
      "name": "petType",
      "title": {
       "default": "Pour quel animal souhaitez-vous une consultation",
       "fr": "Espèce animale"
      },
      "isRequired": true,
      "choices": [
       {
        "value": "cat",
        "text": "Chat"
       },
       {
        "value": "dog",
        "text": "Chien"
       }
      ]
     },
     {
      "type": "text",
      "name": "petRace",
      "startWithNewLine": false,
      "title": "Race",
      "isRequired": true
     },
     {
      "type": "radiogroup",
      "name": "petSexe",
      "title": {
       "fr": "Sexe"
      },
      "isRequired": true,
      "choices": [
       {
        "value": "fs",
        "text": {
         "fr": "Femelle stérilisée"
        }
       },
       {
        "value": "ff",
        "text": {
         "fr": "Femelle fertile"
        }
       },
       {
        "value": "ms",
        "text": {
         "fr": "Mâle stérilisé"
        }
       },
       {
        "value": "mf",
        "text": {
         "default": "Mâle stérilisé",
         "fr": "Mâle fertile"
        }
       },
       {
        "value": "un",
        "text": {
         "default": "Mâle fertile1",
         "fr": "Inconnu"
        }
       }
      ],
      "colCount": 2
     },
     {
      "type": "text",
      "name": "petColor",
      "startWithNewLine": false,
      "title": {
       "fr": "Couleur"
      },
      "isRequired": true
     },
     {
      "type": "text",
      "name": "petName",
      "title": "Nom",
      "isRequired": true
     },
     {
      "type": "text",
      "name": "petBirthdate",
      "startWithNewLine": false,
      "title": "Date de naissance",
      "isRequired": true,
      "inputType": "date"
     },
     {
      "type": "text",
      "name": "weightLb",
      "title": "Poids de l'animal (lb)",
      "inputType": "number"
     },
     {
      "type": "text",
      "name": "weight",
      "startWithNewLine": false,
      "title": "Poids de l'animal (kg)",
      "inputType": "number"
     }
    ],
    "visibleIf": "{inQc} = true",
    "title": "Information sur l'animale"
   },
   {
    "name": "consultPage",
    "elements": [
     {
      "type": "comment",
      "name": "consultWhy",
      "title": {
       "fr": "Quelles sont vos principales préocupations?"
      }
     },
     {
      "type": "comment",
      "name": "consultMore",
      "title": {
       "fr": "Dites-nous en plus à propos de votre animal"
      },
      "description": {
       "fr": "(problème de santé connu, médicament, ...)"
      }
     },
     {
      "type": "radiogroup",
      "name": "consultType",
      "title": {
       "fr": "Quel type de consultation désirez-vous"
      },
      "isRequired": true,
      "choices": [
       {
        "value": "tel",
        "text": {
         "fr": "Téléphonique"
        }
       },
       {
        "value": "video",
        "text": {
         "fr": "Vidéoconférence"
        }
       }
      ]
     }
    ],
    "title": {
     "fr": "Raison de la consultation"
    }
   }
  ],
  "showPrevButton": false,
  "showQuestionNumbers": "off",
  "startSurveyText": {
   "fr": "Demande de réservation"
  },
  "pagePrevText": {
   "fr": "Précédent"
  },
  "pageNextText": {
   "fr": "Suivant"
  },
  "completeText": {
   "fr": "Envoyer votre demande"
  },
  "previewText": {
   "fr": "Suivant"
  },
  "editText": {
   "fr": "Modifier"
  },
//  "showPreviewBeforeComplete": "showAllQuestions",
  "widthMode": "static",
  "width": "1000px"
 }

// Un JOur : https://codesandbox.io/s/surveyjs-react-typescript-tg34ke?file=/package.json:271-295
//https://surveyjs.io/create-free-survey#example