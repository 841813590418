import Paper from '@mui/material/Paper';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import CalendarGet from "../api/CalendarGet";
import React from 'react';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';

import CircularProgress from '@mui/material/CircularProgress';

import { buildCalendarEventsFromGoogleEvents } from './DateUtils'
import { WeekView,
    DateNavigator,
    TodayButton,
    Toolbar,
    AppointmentTooltip} from '@devexpress/dx-react-scheduler-material-ui';

import {
  Scheduler,
  Appointments,
} from '@devexpress/dx-react-scheduler-material-ui';
import "../css/Common.css";
const StyledAppointmentTooltipHeader = styled(AppointmentTooltip.Header)(() => ({ } ) );
const todayButtonMessages = {
  today: 'Aujourd\'hui',
};


class calendar extends React.Component {


    constructor(props) {
        super(props);
        this.selectedDateHandler = this.selectedDateHandler.bind(this)
    }

    state = {
        eventList: [],
        loadingShow: false
    }

    handleCloseLoading = (data) => {
      this.setState({loadingShow: false})
      return data
    };
  
    handleOpenLoading = (data) => {
      return new Promise( (sucess) => { 
        this.setState({loadingShow: true})
        sucess(data)})
    };

    Header = (({
          children, appointmentData, ...restProps
        }) => (
          <StyledAppointmentTooltipHeader
            {...restProps}
            appointmentData={appointmentData}
          ><div>
            <input class="sd-btn sd-btn--action sd-navigation__complete-btn" type="button" title="Prendre ce rendez-vous" value="Prendre ce rendez-vous" onClick={() => this.selectedDateHandler(appointmentData)}/>
          </div>
          </StyledAppointmentTooltipHeader>
        ));


    selectedDateHandler(info) {
        this.props.selectedDate(info);
    }

    componentDidMount() {
        this.handleOpenLoading()
        .then(CalendarGet)
        .then(buildCalendarEventsFromGoogleEvents)
        .then( newList => {
            this.setState({ eventList: newList });
        })
        .then(this.handleCloseLoading)
        
    }


    renderEventContent(eventInfo) {
      return (
        <>
          <b>{eventInfo.timeText}</b>
        </>
      )
    }

    render() {
        return (
          <div class="sd-root-modern"  >
            <div class='sv-components-row'>
              <div class="sd-body sd-body--static" >
                  <div class="sd-page">
                     
                  <h4 class="sd-title sd-page__title"><span className="sv-string-viewer">Sélectionnez votre rendez-vous</span></h4>
                  
                  <div class="sd-action-bar sd-header sd-clearfix">
                    <h5>Aucun rendez-vous ne vous convient?</h5>  
                    <Button class="Btn-red sd-btn " onClick={() => this.selectedDateHandler(-1)} >Rendez-vous d'urgence</Button>
                  </div>
                <Paper>
                  
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={this.state.loadingShow}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>

                  <Scheduler
                      data={this.state.eventList}
                      locale="fr-CA"
                      startDate={new Date()}>
                    <ViewState />
                    
                    <Toolbar />
                      <DateNavigator locale="fr-CA"/>
                      <TodayButton   messages={todayButtonMessages}/>
                    <WeekView  startDayHour={9} endDayHour={22} cellDuration={60}  />
                    <Appointments />

                    <AppointmentTooltip
                        showCloseButton
                        headerComponent={this.Header}
                    />

                  </Scheduler>
                </Paper>

              </div>
            </div>
          </div>
          </div>
           );
       }

}
export default calendar;


/*
<EditingState />
*/