import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';


export const initGA = () => {
  ReactGA.initialize('G-MNNSQ28WMM', {
    titleCase: false,
    siteSpeedSampleRate: 100,
    debug: true
  });
}

function GoogleAnalyticInit() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: location.pathname });
  }, [location]);

  return (
    <></>
  );
}

export default GoogleAnalyticInit;