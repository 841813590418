import SurveyComponent from '../component/SurveyComponent.jsx';
import Calendar from '../component/Calendar.jsx';
import EventComponent from '../component/EventComponent.jsx';
import HeaderComponent from '../component/HeaderComponent.jsx';
import React, { useState, useEffect } from 'react';

import "../css/Common.css";

function Reservation() {
    const [selectedEvent,setSelectedEvent] = useState(null);
    const [emergency,setEmergency] = useState(true); //Revert for normal mode
    const [displayCalendar,setDisplayCalendar] = useState(true);
    const [displaySurvey,setDisplaySurvey] = useState(false);
    
    
    useEffect(() =>  {
        setDisplayCalendar(selectedEvent == null && emergency == false) 
        setDisplaySurvey(!(selectedEvent == null && emergency == false))
    });
    
    function selectedDateHandler(event) {
       if (event === -1) {
        setEmergency(true)
        setSelectedEvent(null);
       } else {
        setEmergency(false)
        setSelectedEvent(event);
       }
    }

    return (<>
        <HeaderComponent pageName='Prise de rendez-vous' extraComponent={displaySurvey && <EventComponent selectedEvent={selectedEvent} emergency={emergency}/>} />
       
        <div>
           { displayCalendar && <Calendar selectedDate={selectedDateHandler}/> }
            { displaySurvey && <SurveyComponent selectedEvent={selectedEvent} emergency={emergency} /> }
        </div>
        </>);
           
}

export default Reservation;



