import { formatDateForDisplay } from './DateUtils';
function EventComponent({ selectedEvent, emergency }) {
    const event = selectedEvent
    const isEmergency = emergency
    return (<>
            <b> { !isEmergency && ' - ' + formatDateForDisplay(new Date(event.startDate)) } { isEmergency && '' } </b> 
        </>);
            //TODO Revert for normal mode : Dès que possible

            // <button >Changer de date</button>
}

export default EventComponent;