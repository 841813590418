export const themeJson = {
  "cssVariables": {
    "--sjs-general-backcolor": "rgba(255, 255, 255, 1)",
    "--sjs-general-backcolor-dark": "rgba(243, 243, 243, 1)",
    "--sjs-general-backcolor-dim": "rgba(46, 172, 180, 1)",
    "--sjs-general-backcolor-dim-light": "rgba(245, 245, 245, 1)",
    "--sjs-general-backcolor-dim-dark": "rgba(234, 234, 234, 1)",
    "--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.43)",
    "--sjs-general-dim-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-general-dim-forecolor-light": "rgba(255, 255, 255, 0.8)",
    "--sjs-primary-backcolor": "rgba(46, 172, 180, 1)",
    "--sjs-primary-backcolor-light": "rgba(46, 172, 180, 0.1)",
    "--sjs-primary-backcolor-dark": "rgba(23, 156, 165, 1)",
    "--sjs-base-unit": "8px",
    "--sjs-corner-radius": "4px",
    "--sjs-shadow-small": "0px 2px 0px 0px rgba(0, 0, 0, 0.2)",
    "--sjs-shadow-inner": "inset 0px 2px 0px 0px rgba(0, 0, 0, 0.1)",
    "--sjs-border-light": "rgba(0, 0, 0, 0.09)",
    "--sjs-border-default": "rgba(0, 0, 0, 0.16)",
    "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
    "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
    "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
    "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
    "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-shadow-medium": "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
    "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
    "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
    "--sjs-special-red": "rgba(229, 10, 62, 1)",
    "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
    "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-green": "rgba(25, 179, 148, 1)",
    "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
    "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-blue": "rgba(67, 127, 217, 1)",
    "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
    "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
    "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
    "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-font-questiontitle-family": "Open Sans",
    "--sjs-font-questiontitle-weight": "600",
    "--sjs-font-questiontitle-color": "rgba(0, 0, 0, 0.91)",
    "--sjs-font-questiontitle-size": "14px",
    "--sjs-font-questiondescription-family": "Open Sans",
    "--sjs-font-questiondescription-weight": "400",
    "--sjs-font-questiondescription-color": "rgba(0, 0, 0, 0.43)",
    "--sjs-font-questiondescription-size": "12px",
    "--sjs-font-editorfont-family": "Open Sans",
    "--sjs-font-editorfont-weight": "400",
    "--sjs-font-editorfont-color": "rgba(22, 22, 22, 1)",
    "--sjs-font-editorfont-size": "12px"
  },
  "themeName": "solid",
  "colorPalette": "light",
  "isPanelless": false
}