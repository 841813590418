import { format } from "date-fns";
import { fr } from 'date-fns/locale'

const PRESENTATION_DATE_FORMAT = "dd MMM H:mm"
const SYSTEM_DATE_FORMAT = "yyyy-MM-dd_HH-mm"

export function buildCalendarEventsFromGoogleEvents(calEvents) {
       let newList = []
       calEvents.forEach((event)=> {
           console.log("New event : " + event.start.dateTime)
           newList.push(convertGoogleEventToCalendar(event))
           }
       );
       return newList
}

export function convertGoogleEventToCalendar(event) {
    var endDate = new Date(event.start.dateTime)
    endDate = endDate.setMinutes(endDate.getMinutes()+30)
    return {id: event.id, title: "Plage disponible", startDate: event.start.dateTime, endDate: endDate}
}

export function formatDateForDisplay(dateToDisplay) {

    return format(dateToDisplay, PRESENTATION_DATE_FORMAT, {locale: fr})
}

export function formatDateForSystem(dateToDisplay) {

    return format(dateToDisplay, SYSTEM_DATE_FORMAT, {locale: fr})
}